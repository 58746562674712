<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card
          :loading="loading"
          :disabled="loading"
          loader-height="2"
          class=""
        >
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                @click="add()"
                rounded
                color="primary"
                elevation="0"
                small
                class="mr-2"
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="search"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
              <v-btn @click="getData()" icon small class="ml-2"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-text>
            <v-data-table
              class="cursor-pointer"
              @click:row="openDetails"
              :headers="headers"
              dense
              :search="search"
              :items="getName"
              :items-per-page="-1"
              hide-default-footer
            >
              <template #[`item.botid`]="{ item }">
                <span
                  style="text-decoration: none"
                  >{{ item.botname }}</span
                >
              </template>
              <template #[`item.from`]="{ value }">
                {{ $nova.formatDate(value) }}
              </template>
              <template #[`item.to`]="{ value }">
                {{ $nova.formatDate(value) }}
              </template>
              <template v-slot:[`item.stage`]="{ value }">
                <v-chip small label color="success" v-if="value === 2"
                  >Closed</v-chip
                >
                <v-chip small label text color="warning" v-if="value === 1"
                  >Review</v-chip
                >
                <v-chip small label text color="info" v-if="value === 0"
                  >Open</v-chip
                >
              </template>
              <template #[`item.actions`]="{ item }">
                <div class="">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small @click.stop="editBot(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </div>

                <!-- <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="view-on-hover-item-d mx-0 px-0"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="pa-0 border-left-default">
                    <v-list-item>
                      <v-list-item-title
                        ></v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog v-model="createpopup" max-width="600" persistent>
          <v-card>
            <v-card-text
              class="primary white--text d-flex align-center py-2 px-4"
            >
              <span class="subtitle-1 white--text" v-if="!editId"
                >Create Projects</span
              >
              <span class="subtitle-1 white--text" v-else>Update Projects</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="createpopup = false" class="ml-2 white--text"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown
                    label="Select Bots"
                    v-model="allbots"
                    :items="getBots"
                    itemtext="name"
                    itemvalue="_id"
                  />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date
                    v-model="dateOne"
                    label="From"
                    :max="$nova.formatDateISO(new Date())"
                  />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date
                    v-model="dateTwo"
                    label="To "
                    :min="dateOne"
                  />
                </v-col>
              </v-row>
              <v-card-text class="d-flex">
                <v-spacer></v-spacer>
                <v-btn @click="update()" v-if="editId" color="primary"
                  >Update</v-btn
                >
                <v-btn @click="createBot()" v-else color="primary">Save</v-btn>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
// import CallLog from "../../../components/CallLog.vue";
export default {
  
  data() {
    return {
      editId: null,
      dateOne: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString(),
      dateTwo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString(),
      allbots: "",
      name: "",
      getBots: [],
      search: "",
      getName: [],
      createpopup: false,
      loading: false,
      headers: [
        { text: "Name", value: "botid" },
        { text: "From", value: "from" },
        { text: "To", value: "to" },
        { text: "Actions", value: "actions" },
        { text: "Status", value: "stage" },
      ],
    };
  },
  components:{
    // CallLog,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/bot/project/get")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.getName = ele.data.data;
            console.log(this.getName, "FFFFFF");
            this.axios
              .post("v2/conserve/bot/get")
              .then((ele) => {
                this.getBots = ele.data.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createBot() {
      this.loading = true;
      let obj = {
        botid: this.allbots,
        from: this.dateOne,
        to: this.dateTwo,
      };
      console.log(obj);
      this.axios
        .post("/v2/bot/project/add", { data: obj })
        .then((ele) => {
          console.log(ele);
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "added successfully");
            this.getData();
            this.createpopup = false;
          }else {
            throw new Error("Error adding Bot")
          }          
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    add() {
      this.createpopup = true;
      this.allbots = "";
      this.dateOne = "";
      this.dateTwo = "";
      this.editId = null;
    },
    editBot(item) {
      console.log(item);
      this.editId = item._id;
      this.allbots = item.botid;
      this.dateOne = item.from;
      this.dateTwo = item.to;
      this.createpopup = true;
    },
    update() {
      console.log(this.dateOne, this.dateTwo, this.allbots);
      this.loading = true;
      this.createpopup = false;
      let data = { from: this.dateOne, to: this.dateTwo, botid: this.allbots };
      this.axios
        .post("/v2/bot/project/edit/" + this.editId, { data })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "updated successfully");
            this.getData();
          } else {
            throw new Error("Error updating Bots");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDetails(item) {
      this.$nova.gotoLink({path: "/bot/project/view/" + item._id});
    },
  },
};
</script>